import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';
import { SectionNewsletter } from '../PageBuilder/SectionBuilder';
import { injectIntl } from '../../util/reactIntl';

const newsletterSectionType = 'newsletter';
const newsletterSectionId = 'newsletter'

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error } = props;

  const pageData = pageAssetsData?.[camelize(ASSET_NAME)]?.data;

  // Find the correct custom section based on section id
  const newsletterSectionIdx = pageData?.sections.findIndex(s => s.sectionId === newsletterSectionId);
  const newsletterSection = pageData?.sections[newsletterSectionIdx];

  // Define the necessary props for the custom section
  const customNewsletterSection = {
    ...newsletterSection,
    sectionId: newsletterSectionId,
    sectionType: newsletterSectionType,
    // listings: listings,
  };


  // Replace the original section with the custom section object
  // in custom page data
  const customPageData = pageData
    ? {
      ...pageData,
      sections: pageData.sections.map((s, idx) =>
        idx === newsletterSectionIdx ? customNewsletterSection : s
      ),
    }
    : pageData;

  return (
    <PageBuilder
      // pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
      pageAssetsData={customPageData}
      options={{
        sectionComponents: {
          [newsletterSectionType]: { component: SectionNewsletter },
        },
      }}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
